:root {
  --background: #f0f3f6;
  --modalBackground: #f8f9fb;
  /* Primary */
  --primaryDefault: #2c6fae;
  --primaryLightest: #e0eaf4;
  --primaryLight: #a4c5e5;
  --primaryHover: #5191cd;
  --primaryDark: #145a9c;
  /* Black */
  --black: #000000;
  --blackDefault: #22282d;
  --blackBackground: #f0f3f6;
  --blackDivider: #e2e6e8;
  --grayLighter: #d2d7df;
  --grayLight: #bec3cc;
  --gray: #97a0a9;
  --grayDark: #5c6976;
  /* White */
  --white: #ffffff;
  --ghostwhite: #f9fafa;
  /* Yellow */
  --warningDefault: #fac032;
  --warningDarker: #cd9200;
  --warningLight: #fcdf98;
  --warningLightest: #fcf0d7;
  /* Green */
  --positiveDefault: #0fc285;
  --positiveDarker: #00aa6d;
  --positiveLight: #1edd9b;
  --positiveLightest: #d8faef;
  /* Red */
  --negativeDefault: #e53243;
  --negativeDarker: #e53243;
  --negativeLight: #ff6969;
  --negativeLightest: #f9dfdd;
  /* Shadow */
  --shadowBlack: rgba(16, 30, 115, 0.05);
  --shadowModal: rgba(37, 40, 43, 0.32);
}

@font-face {
  font-family: 'Lato-Regular';
  /* src: local('Lato-Regular'), url(../fonts/Lato-Regular.ttf) format('truetype'); */
}
@font-face {
  font-family: 'Lato-Bold';
  /* src: local('Lato-Bold'), url(../fonts/Lato-Bold.ttf) format('truetype'); */
}
a {
  text-decoration: none;
}
body {
  font-family: 'Lato-Regular';
}

.private_container {
  padding: 24px;
}

label {
  color: var(--blackDefault);
}

.link {
  color: var(--primaryDefault);
}

.custom-textarea {
  resize: none;
}

.text-right {
  text-align: right;
}

.custom-input {
  color: var(--grayDark);
  background: var(--white);
  border: 1px solid var(--blackDivider);
  border-radius: 8px !important;
}

.input-error,
.input-error:hover,
.input-error:focus {
  border-color: var(--negativeDefault) !important;
}

.custom-input:hover,
.custom-input:focus {
  border-color: var(--primaryHover);
}

.custom-input:focus,
.input-error:focus {
  box-shadow: none;
}

.custom-input::placeholder {
  color: var(--grayLight);
  text-transform: capitalize;
}

.custom-input-search {
  width: 250px;
}

.error-text {
  color: var(--negativeDefault);
}

.copyInput {
  float: left;
  width: 70%;
  display: inline-block;
}

.copyInputBtn {
  float: right;
  width: 28%;
  display: inline-block;
  cursor: pointer;
}

.rightInput-icon,
.leftInput-icon {
  color: var(--grayDark);
}

.inner-addon {
  position: relative;
}

.inner-addon .rightInput-icon,
.inner-addon .leftInput-icon {
  position: absolute;
  padding: 7px 12px;
}

.inner-addon .leftInput-icon {
  pointer-events: none;
}

.right-addon .rightInput-icon {
  right: 0px;
  top: 0px;
}
.left-addon .leftInput-icon {
  left: 0px;
  top: 0px;
}

.right-addon input {
  padding-right: 35px;
}
.left-addon input {
  padding-left: 35px;
}

.sub-label {
  color: var(--grayDark);
}

/* Primary button */
.primaryButton {
  font-family: 'Lato-Bold';
  font-size: 15px;
  color: var(--white);
  background-color: var(--primaryDefault);
  border-radius: 8px;
}
.primaryButton:hover {
  color: var(--white);
  background-color: var(--primaryHover);
}
.primaryButton:disabled {
  background-color: var(--grayLighter);
}

/* Secondary button */
.secondaryButton {
  font-family: 'Lato-Bold';
  font-size: 15px;
  background-color: transparent;
  border-radius: 8px;
}
.secondaryButton-red {
  color: var(--negativeDefault);
  border-color: var(--negativeDefault);
}
.secondaryButton-blue {
  color: var(--primaryDefault);
  border-color: var(--primaryDefault);
}
.secondaryButton-red:hover {
  color: var(--negativeDefault);
  background-color: var(--blackBackground);
}
.secondaryButton-blue:hover {
  color: var(--primaryDefault);
  background-color: var(--blackBackground);
}
.secondaryButton:disabled {
  color: var(--grayLight);
  background-color: transparent;
}

/* Tags */
.blueTag {
  background-color: var(--primaryLightest);
  color: var(--primaryDark);
}
.redTag {
  background-color: var(--negativeDarker);
  color: var(--negativeLightest);
}
.greenTag {
  background-color: var(--positiveLightest);
  color: var(--positiveDarker);
}
.yellowTag {
  background-color: var(--warningLightest);
  color: var(--warningDarker);
}
.blueTag {
  background-color: var(--primaryLightest);
  color: var(--primaryDark);
}
.naTag {
  border: 1px solid var(--grayLight);
  color: var(--grayLight);
}
.tooltipTag {
  background-color: var(--grayDark);
  color: var(--white);
}

.tagBig {
  border-radius: 8px;
  font-size: 16px;
  font-family: 'Lato-Regular';
  font-weight: normal;
}
/* Square buttons */
.btn-sq {
  width: 48px !important;
  height: 48px !important;
}

.buttonLeftMargin {
  margin-left: 16px;
}
.cp {
  cursor: pointer;
}

.uppercaseTitle {
  color: var(--grayDark);
  font-size: 13px;
  text-transform: uppercase;
}

.boldTitle {
  font-size: 18px;
  font-family: 'Lato-Bold';
  color: var(--blackDefault);
}

.primaryHover:hover {
  color: var(--primaryDefault);
}

.secondaryTitle-bold {
  color: var(--grayDark);
  font-family: 'Lato-Bold';
}

.secondary-title {
  color: var(--grayDark);
}
.smallTitle {
  font-size: 13px;
}

.additionalLabel-input {
  color: var(--gray);
  font-weight: normal;
}

.cancelButton {
  color: var(--gray);
  cursor: pointer;
}

.primaryTitle {
  color: var(--primaryDefault);
  font-family: 'Lato-Bold';
}

.muted {
  color: var(--grayLight);
}

.grayTitle {
  color: var(--grayDark);
  font-family: 'Lato-Bold';
}
.grayLightTitle {
  color: var(--grayLight);
  font-family: 'Lato-Bold';
}
.grayTitle-small {
  font-size: 13px;
  color: var(--gray);
}

.grayTitleDark {
  color: var(--grayDark);
}

.grayDescription {
  font-size: 18px;
  color: var(--grayDark);
}

.blackDescription {
  font-size: 18px;
  color: var(--blackDefault);
}
.modalTitle {
  font-family: 'Lato-Bold';
  color: var(--blackDefault);
}

.lineWithText {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--blackDivider);
  line-height: 0.1em;
}
.lineWithText span {
  background: var(--modalBackground);
  font-family: 'Lato-Bold';
  font-size: 15px;
  color: var(--blackDefault);
  text-transform: uppercase;
}
.messageBlock {
  border-radius: 8px;
  padding: 4px;
}
.errorBlock {
  border: 1px solid var(--negativeLight);
  background-color: var(--negativeLightest);
}
.warningBlock {
  border: 1px solid var(--warningLight);
  background-color: var(--warningLightest);
}
.messageBlock-icon {
  margin: 0 4px;
}
.errorBlock-icon {
  color: var(--negativeDefault);
}
.warningBlock-icon {
  color: var(--warningDefault);
}
/* Cases */
.caseForm {
  display: flex;
  justify-content: space-between;
}

.caseForm-left {
  width: 52%;
}

.caseForm-right {
  width: 28%;
}

.dropdown {
  width: 100%;
  color: var(--grayLight);
  background-color: var(--white);
  border: 1px solid var(--blackDivider);
  border-radius: 8px;
  position: relative;
}
.dropdown:hover,
.dropdown:target,
.dropdown--active {
  border-color: var(--primaryHover);
}
.dropdown--active {
  border-radius: 8px 8px 0 0;
}
.dropdown--selected {
  color: var(--grayDark) !important;
}
.dropdownHeader {
  padding: 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown_items {
  width: inherit;
  padding: 4px 0;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  border: 1px solid var(--primaryHover);
  box-shadow: 0px 12px 26px var(--shadowBlack);
  background-color: var(--white);
  position: absolute;
  top: 48px;
  left: 0;
  z-index: 2;
  max-height: 174px;
  overflow: auto;
}
.scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 0px;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--primaryLightest);
  border: 1px solid var(--primaryLight);
  border-radius: 4px;
}

.form-check {
  display: inline-block;
}

.form-check-input:checked {
  background-color: var(--primaryDefault);
  border-color: var(--primaryDefault);
}

.form-check-input {
  cursor: pointer;
}

/* Schedule */

.schedule_leftBlock {
  width: 46%;
}

.schedule_rightBlock {
  width: 26%;
}

.schedule_card {
  background-color: var(--white);
  box-shadow: 0px 8px 24px var(--shadowBlack);
  border-radius: 8px;
  padding: 16px;
}

.statistic_text {
  width: 82%;
}

/* Modal */

.modal-content {
  border: none !important;
  box-shadow: 0px 12px 40px var(--shadowModal);
}

.modal-header {
  border-bottom: none !important;
}

.modal-body {
  background-color: var(--modalBackground);
}

.modalBodyDelete {
  background-color: var(--white);
}

.modalHeader {
  justify-content: center !important;
  padding-top: 2rem;
}

/* Custom radio input */
.radioInputs__item {
  margin-bottom: 1.2em;
  display: block;
  position: relative;
  padding-left: 1.8em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radioInputs__item input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radio-checkmark {
  position: absolute;
  top: 0.2em;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: var(--transparent);
  border: solid 0.05em var(--primaryDefault);
}

.radio-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.radioInputs__item input:checked ~ .radio-checkmark:after {
  display: block;
}

.radioInputs__item input:checked ~ .radio-checkmark {
  background-color: var(--primaryDefault);
}

.radioInputs__item .radio-checkmark:after {
  top: 5px;
  right: 5px;
  width: 8px;
  height: 8px;
  background-color: var(--blackBackground);
  border-radius: 100%;
}

/* Custom checkbox input */

.checkboxInputs__item {
  display: block;
  position: relative;
  margin-bottom: 1.2em;
  padding-left: 2em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkboxInputs__item input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: solid 1.5px var(--primaryDefault);
  border-radius: 4px;
}

.checkboxInputs__item:hover input ~ .checkbox-checkmark {
  background-color: var(--primaryDefault);
}

.checkboxInputs__item input:checked ~ .checkbox-checkmark {
  background-color: var(--primaryDefault);
}

.checkbox-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkboxInputs__item input:checked ~ .checkbox-checkmark:after {
  display: block;
}

.checkboxInputs__item .checkbox-checkmark:after {
  bottom: 5px;
  right: 5px;
  width: 6px;
  height: 12px;
  border: solid var(--white);
  border-width: 0 1.8px 1.8px 0;
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
}

/* SESSION LIST */
.customMultiSelect {
  height: 39px;
}

.customMultiSelect .dropdownHeader {
  padding-top: 7px;
}

.customMultiSelect .dropdown_items {
  top: 37px;
}

.customMultiSelectItem .checkbox-checkmark {
  top: 18px;
  left: 10px;
}

.min {
  font-weight: 100;
  font-size: 13px;
  color: #97a0a9;
}

.dateRange {
  display: flex;
  align-items: center;
}

.timeRange {
  min-width: 100px;
}

.startSessionBlock {
  background: #fff;
  border-radius: 5px;
  text-align: center;
  min-height: 336px;
}

.circle-wrapper {
  width: 55%;
  margin: 0 auto;
}

.circle-wrapper .circle {
  display: inline-block;
  width: 100%;
  height: 0;
  padding: 50% 0;
  border-radius: 50%;
  background: #e0eaf4;
}

.circle-wrapper .circle.circleGreen {
  background: #0fc285;
  font-size: 5.5vw;
  color: #fff;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

.circle-wrapper .circle .date {
  margin-top: -18%;
  color: #97a0a9;
  font-size: 1.1vw;
}

.circle-wrapper .circle .time {
  color: #000000;
  font-size: 1.6vw;
}

@media only screen and (max-width: 1024px) {
  .schedule_leftBlock {
    width: 60%;
  }

  .schedule_rightBlock {
    width: 34%;
  }
}

.optionContainer {
  border: 1px solid var(--primaryDefault);
  margin: 10px;
  padding: 10px;
  /* max-width: 50%; */
  display: flex;
  justify-content: space-between;
}

.optionLeft {
  width: 85%;
  text-align: center;
}

.optionContainer .title {
  min-width: 50%;
}

.optionContainer .delete {
  color: var(--negativeDefault);
  cursor: pointer;
}

.optionContainer .edit {
  color: var(--primaryDefault);
  cursor: pointer;
}

.optionContainer i {
  margin-left: 20px;
  margin-right: 20px;
}

.greenBorder {
  color: green;
  border: 1px solid green;
  padding: 3px;
}

.fieldError {
  color: var(--negativeDarker);
  display: block;
}

.isNoLead {
  background-color: rgb(229, 50, 67, 0.1);
}

.ql-editor {
  background-color: #fff;
  min-height: 250px;
}

.dropzone {
  border: 1px solid var(--primaryDark) !important;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.imageLoaderImageWr i {
  padding: 10px;
  color: var(--negativeDarker);
  cursor: pointer;
  border: 1px solid var(--negativeDarker) !important;
  margin: 10px;
  border-radius: 5px;
}

.imageLoaderImage {
  height: 200px;
}

.imageLoaderImageWr {
  display: flex;
  justify-content: center;
}

.block-error {
  background-color: rgb(229, 50, 67, 0.1);
  color: #000;
  border: 1px solid rgb(229, 50, 67, 0.1);
  text-align: center;
  padding: 20px;
  margin: 20px;
}

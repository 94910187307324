#root {
  background: var(--background);
}

/* Public */
.public_app {
  background: var(--background);
  display: flex;
  justify-content: center;
  padding-top: 15vh;
  padding-bottom: 5vh;
  min-height: 100vh;
}

.public_wrapper {
  width: 450px;
}

.signIn img {
  height: 100px;
}

/* Private */
.private_app {
  background: var(--background);
  display: flex;
  min-height: 100vh;
}

.private_header {
  border-left: 1px solid var(--blackDivider);
  background-color: var(--white);
  height: 98px;
}

.private_header-content {
  border-left: 1px solid var(--blackDivider);
  height: inherit;
  min-width: 28%;
}

.private_main {
  flex: 9.5;
}

.private_body {
  background-color: var(--white);
  width: 94%;
  margin: 1.6rem auto;
  box-shadow: 0px 8px 24px var(--shadowBlack);
  border-radius: 8px;
}

.create_body {
  width: 94%;
  margin: 1.6rem auto;
}

.sidebar-wrapper {
  flex: 1.5 1;
  background: var(--white);
  border-right: 1.5px solid var(--blackDivider);
}

.sidebar-top {
  text-align: center;
  height: 100px;
  border-bottom: 3px solid var(--background);
}

.sidebar-wrapper img {
  height: 70px;
}

.navLink {
  font-size: 20px;
  display: flex;
  color: var(--grayDark);
  font-family: 'Lato-Bold';
  text-decoration: none;
}
.navLink:hover,
.navLink:hover .navLink_icon {
  transition: all 0.5s ease-out;
  color: var(--primaryDefault);
}
.navLink--active {
  color: var(--primaryDefault);
}
.navLink--active .navLink_icon {
  color: var(--primaryDefault);
}
.navLink_icon {
  color: var(--grayLight);
}

/* Switcher */

.insSwitcher {
  cursor: pointer;
}

.insBlock_items {
  width: inherit;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 12px 26px var(--shadowBlack);
  border-radius: 8px;
  padding: 4px 0;
  background-color: var(--white);
  position: absolute;
  color: var(--blackDefault);
  max-height: 174px;
  overflow: auto;
}

.dropdownItem {
  padding: 15px 15px 15px 36px;
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dropdownItem--active {
  background-color: var(--primaryLightest);
  font-weight: bold;
}

.dropdownItem input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radioCheck {
  position: absolute;
  top: 18px;
  left: 10px;
  height: 1.246em;
  width: 1.246em;
  border-radius: 100%;
  background-color: transparent;
  border: solid 0.05em var(--gray);
}

.radioCheck--active {
  background-color: var(--primaryDefault);
  border-color: var(--primaryDefault);
}

.dropdownItem:hover .radioCheck {
  background-color: var(--primaryDefault);
  border-color: var(--primaryDefault);
}

.radioCheck:after {
  content: '';
  position: absolute;
  display: none;
}

.dropdownItem input:checked ~ .radioCheck:after {
  display: block;
}

.dropdownItem .radioCheck:after {
  top: 0.3em;
  right: 0.3em;
  width: 0.5em;
  height: 0.5em;
  background-color: var(--white);
  border-radius: 100%;
}
.dropdownItem .radioCheck--active:after,
.dropdownItem:hover .radioCheck:after {
  background-color: var(--white);
  display: block;
}

.headerDropDownItems {
  margin-top: 100px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 12px 26px var(--shadowBlack);
  border-radius: 8px;
  padding: 4px 0;
  background-color: var(--white);
  position: absolute;
  color: var(--blackDefault);
  right: 3px;
  top: -10px;
  z-index: 5;
}

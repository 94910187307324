.dataTable {
  width: 100%;
  background: var(--white);
}

.dataTable tr td:first-child {
  padding-left: 24px;
}
.dataTable tr td:last-child {
  padding-right: 24px;
}
.editLink {
  cursor: pointer;
  padding-right: 0.5rem;
  color: var(--primaryDefault);
}
.editLink i {
  color: var(--primaryHover);
  padding-right: 0.5rem;
  font-size: 22px;
}

.deleteLink {
  cursor: pointer;
  padding-right: 0.5rem;
  color: var(--negativeDarker);
}
.deleteLink i {
  color: var(--negativeDefault);
  padding-right: 0.5rem;
  font-size: 22px;
}

.arrangeLink {
  cursor: pointer;
  padding-right: 0.5rem;
  color: var(--primaryHover);
}

.arrangeLink i {
  color: var(--primaryHover);
  padding-right: 0.5rem;
  font-size: 22px;
}

.editLink i,
.deleteLink i {
  padding-right: 0.2rem;
}

.trow-header {
  border-bottom: 1px solid var(--grayLighter);
  background-color: var(--ghostwhite);
}

.trow-content {
  border-bottom: 1px solid var(--blackDivider);
}

.td-action {
  color: var(--grayLight);
}

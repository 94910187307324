.modal.show {
  display: block;
}

.modal .modal-footer {
  justify-content: space-between;
}

.custom-date_input {
  position: relative;
  padding-left: 46px;
  padding-right: 10px;
  color: var(--gray);
}

.custom-date_input:hover:enabled {
  border-color: var(--primaryHover);
  cursor: pointer;
}

.custom-date_input::-webkit-calendar-picker-indicator {
  width: 16px;
  height: 9px;
  margin-top: 4px;
  /* background: url('../icons/downArrow.svg') no-repeat; */
}

.custom-date {
  position: relative;
}

.custom-date i {
  position: absolute;
  z-index: 1;
  top: 6px;
  left: 14px;
  font-size: 18px;
  color: var(--gray);
}
